import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql } from 'gatsby'

import Layout from '../components/LayoutRU'
import BlogRoll from '../components/BlogRoll'

import Certyfikat from '../../static/img/certyfikatTUV.webp'


import "../components/layout.css"

export const JakoscPageTemplateRU = ({ intro }) => (
         <div>
           <div>
             <div></div>
    </div>
    <section className="section section--gradient antyPadding">
             <div className="container">
        <div className="section AboutP antyPadding">
                 <div className="columns">
                   <div className="column is-10 is-offset-1 StoPro">
                     <div className="content">
                       <div className="columns">
                  <div className="column is-12">
                  </div>
                       </div>
                <div className=""><h3 className="has-text-weight-semibold is-size-2 headingCSS5">
                  {intro.heading}<hr className="hr"></hr>
                </h3>
                  <div className="grid-containerJakosc">
                    <div className="JakoscOpis">«Экохрон» стремится к тому, чтобы качество было не только вывеской, но и характеристикой, подтвержденной всеми клиентами.
О высоком качестве продуктов компании «Экохрон» свидетельствуют:</div>
                    <div className="jo1 joUniversall"><p className="Naglowek">1. собственная лаборатория</p><p className="opisJakosc">Компания располагает собственной лабораторией, которая тестирует изделия с точки зрения их прочности и устойчивости к внешним факторам.</p></div>
                    <div className="jo5 joUniversall"><p className="Naglowek">5. обладающие знанием и опытом сотрудники</p><p className="opisJakosc">За качество наших изделий отвечает коллектив специалистов, чьи знание и опыт обеспечивают развитие и успех компании.</p></div>
                    <div className="jo2 joUniversall"><p className="Naglowek">2. соответствие нормам</p><p className="opisJakosc">Все изделия также тестируются во внешних лабораторных учреждениях на соответствие нормам EN-PN 516 (кровельная коммуникация) и EN-PN 517 (страховочные крюки).</p></div>
                    <div className="jo6 joUniversall"><p className="Naglowek">6. сотрудничество с Институтом строительной техники и Техническим и испытательным строительным институтом</p><p className="opisJakosc">Компания «Экохрон» сотрудничает с Институтом строительной техники (ITB) в Варшаве и Остравским отделением Техническим и испытательным строительным институтом (TZUS) в Праге.
Именно мы первыми, в 2004 году, внедрили высококачественные кровельные крюки, защищающие от падения с большой высоты.
С этого времени все наши изделия, соответствующие гармонизированным нормам ЕС, мы внедряем во взаимодействии с TZUS, что гарантирует корректность конструкции и высокого качества продукции.</p></div>
                    <div className="jo3 joUniversall"><p className="Naglowek">3. Внедрение ISO-9001</p><p className="opisJakosc">В 2000 года наша компания внедрила Систему Управления Качеством ISO-9001, постоянно контролируемую RW TUV. Работа в соответствии с системой ISO укрепили позиции компании на национальном рынке и позволили систематически и постоянно увеличивать качество изделий, что открыло доступ на европейский рынок.</p></div>
                    <div className="jo7 joUniversall"><p className="Naglowek">7. доверие клиентов в Польше и других европейских странах</p><p className="opisJakosc">Благодаря высокому качеству предлагаемых продукции изделия марки «Экохрон» доступны также во многих европейских странах.</p></div>
                    <div className="jo4 joUniversall"><p className="Naglowek">4. собственный цех окраски порошковым методом</p><p className="opisJakosc">В результате инвестиций в инфраструктуру и парк станков был создан собственный цех порошковой окраски, продолжился процесс развития автоматизации процессов, что непосредственном образом сказалось на росте эффективности и скорости реагирования на нужды клиента.</p></div>
                    <div className="jo8 joUniversall"><p className="Naglowek">8. новые направления развития</p><p className="opisJakosc">Тесно сотрудничая со своими клиентами и отслеживая потребности рынка, мы внедряем современные продукты, становясь лидером в сфере производства кровельного оснащенияя и ориентиром для конкурентов.</p></div>
                    <div className="JakoscOpis2">Нас радует систематический рост количества компаний, постоянно сотрудничающих с нашим предприятием. В настоящее время наша фирма поставляет свои продукты почти 500 польским клиентам. У нас также есть клиенты за рубежом.

Благодарим всех, кто с нами все эти долгие годы и приглашаем всех, кто еще не принял решения.</div>
                  </div>



                       </div>
                <div className="imgALL divCertyfikat"><img alt=" " className="imgCertyfikatJakosci"  src={Certyfikat}></img></div>
                       <div className="columns artykulyProd">
                         <div className="column is-12 has-text-centered">

                         </div>
                       </div>
                       <div className="column is-12 wpisy">
                         <h3 className="has-text-weight-semibold is-size-2">
                           Najnowsze wpisy:
                         </h3>
                         <BlogRoll />
                         <div className="column is-12 has-text-centered">
                           <Link className="btn" to="/blog">
                             Czytaj więcej
                           </Link>
                         </div>
                       </div>
                     </div>
                   </div>
                 </div>
               </div>
             </div>
           </section>
         </div>
       );

JakoscPageTemplateRU.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  heading: PropTypes.string,
  subheading: PropTypes.string,
  description: PropTypes.string,
  intro: PropTypes.shape({
    blurbs: PropTypes.array,
  }),
  gallery: PropTypes.shape({
    blurbs: PropTypes.array,
  }),
}

const JakoscPageRU = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout>
      <JakoscPageTemplateRU
        image={frontmatter.image}
        heading={frontmatter.heading}
        subheading={frontmatter.subheading}
        description={frontmatter.description}
        intro={frontmatter.intro}
        gallery={frontmatter.gallery}
      />
    </Layout>
  )
}

JakoscPageRU.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default JakoscPageRU

export const pageQuery = graphql`
         query JakoscPageTemplateRU {
           markdownRemark(
             frontmatter: { templateKey: { eq: "jakosc-pageRU" } }
           ) {
             frontmatter {
               heading
               description
               subheading
               intro {
                 blurbs {
                   image {
                     childImageSharp {
                       fluid(maxWidth: 780, quality: 100) {
                         ...GatsbyImageSharpFluid
                       }
                     }
                   }
                   text
                 }

                 heading
                 description
               }
               gallery {
                 blurbs {
                   image {
                     childImageSharp {
                       fluid(maxWidth: 780, quality: 100) {
                         ...GatsbyImageSharpFluid
                       }
                     }
                   }

                 }

               }
             }
           }
         }
       `;
